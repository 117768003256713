import React, { Component } from "react";

import { withRouter } from "react-router";

import styles from './Impressum.css';
import mainStyles from './Main.css';

import Close from './Close.jsx';

class Impressum extends Component {
    constructor(props) {
        super(props)
    }

  gridWheel(e) {
    e.currentTarget.scrollLeft += (e.deltaY);
  }

  render() {
    return (
      <div className={[mainStyles.Component, styles.Component, mainStyles.Border].join(' ')}>
        <Close onClick={() => this.props.history.push('/')} />
        <div className={styles.Content}>
            <div className={styles.ContentElement} dangerouslySetInnerHTML={{__html: this.props.data.contact}} />
            <div className={styles.ContentElement} dangerouslySetInnerHTML={{__html: this.props.data.info}} />

            <div className={styles.ContentElement} onWheel={(e) => this.gridWheel(e)}>
              <div className={styles.ContentElementColumns} dangerouslySetInnerHTML={{__html: this.props.data.policy}}>
              </div>
            </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Impressum);


