import React, { Component } from "react";

import styles from './Cell.css';
import mainStyles from './Main.css';

class Cell extends Component {
  constructor() {
    super();

    this.state = {
      title: ""
    };
  }

  render() {
    const classNames = [styles.Component, mainStyles.HalfBorder].concat(this.props.classNames || []);

    if (this.props.cell.selectable) {
      classNames.push(styles.Selectable)
    }

    if (!this.props.cell.content) {
      classNames.push(styles.Empty)
    }


    const onClickHandler = () => {
      if (!this.props.cell.selectable) return;
      this.props.onClick(this.props.cell);
    }

    return (
      <div 
        className={classNames.join(' ')} 
        style={this.props.style} 
        onClick={onClickHandler}
      >
        {this.props.cell.content}
      </div>
    );
  }
}
export default Cell;


