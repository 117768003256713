import React, { Component } from "react";

import Site from '../presentation/Site.jsx';

import cms from '../cms';

class SiteContainer extends Component {
  constructor() {
    super();
    this.state = {loading: true};
  }

  componentDidMount() {
    this.setState({loading: true});
    cms.getItems("frontpage", {fields: '*.*.*'})
      .then(res => this.setState({frontpage: res.data[0]}))
      .catch(error => console.error(error))
      .finally(() => this.setState({loading: false}));
  }

  render() {
    return (
      <Site loading={this.state.loading} frontpage={this.state.frontpage} />
    );
  }
}
export default SiteContainer;