import React, { Component } from "react";

import Kontakt from '../presentation/Kontakt.jsx';

import cms from '../cms.jsx';

function href(text, prefix, replace="") {

    return encodeURI(prefix + (text || '').replace(/((\r\n|\n|\r)|(\s+))/gm, replace));
}

class KontaktContainer extends Component {
  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    cms.getItem("contact", 1)
      .then(res => {
        this.setState({contact: [
            {text: res.data.phone, href: href(res.data.phone, 'tel:')},
            {text: res.data.email_address, href: href(res.data.email_address, 'mailto:')},
            {text: res.data.adress, href: href(res.data.adress, 'https://www.google.com/maps?q=', " ")}
        ]})
      })
      .catch(error => console.error(error));
  }

  render() {
    return (
      <Kontakt contact={this.state.contact} />
    );
  }
}
export default KontaktContainer;