import React, { Component } from "react";

import Impressum from '../presentation/Impressum.jsx';

import cms from '../cms.jsx';

class ImpressumContainer extends Component {
  constructor() {
    super();
    this.state = {data:{}}
  }

  componentDidMount() {
    cms.getItem("imprint", 1)
      .then(res => this.setState({data: res.data}))
      .catch(error => console.error(error));
  }

  render() {
    return (
      <Impressum data={this.state.data} />
    );
  }
}
export default ImpressumContainer;