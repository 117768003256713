import React, { Component } from "react";

import Cell from './Cell.jsx';
import Close from './Close.jsx';
import styles from './Grid.css';
import mainStyles from './Main.css';


import logoImage from '../../assets/logo.svg';
import closeImage from '../../assets/close.svg';

class Grid extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.checkMobile())
    this.checkMobile()
  }

  checkMobile() {
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    this.setState({mobile: width < 1024});
  }

  selectCell(cell, index) {
    this.props.onCellSelected(cell, index);
  }

  getCellStyle(cell) {
    const left = (cell.edges.l-0.05);
    const top = (cell.edges.t-0.05);
    const bottom = (cell.edges.b-0.05);
    const right = (cell.edges.r-0.05);


    return {
      backgroundColor: cell.bg,
      left: `${left}%`,
      top: `${top}%`,
      bottom: `${bottom}%`,
      right: `${right}%`,
      height: `${Math.abs(100-top-bottom)}%`,
      width: `${Math.abs(100-left-right)}%`,
      zIndex: cell.edges.z
    }
  }

  render() {
    const cells = (this.props.cells || []).map((cell) => Object.assign({}, cell))

    return (
      <div className={[styles.Component, mainStyles.HalfBorder, this.props.className].join(" ")}>
        {this.state.selectedIndex !== undefined && (
        <Close className={styles.Close} onClick={() => this.selectCell(undefined)} />
        )}
        <div className={[styles.Layout].join(' ')}>
          {cells.map((cell, cellIndex) => (
            <Cell classNames={styles.GridCell} style={this.getCellStyle(cell)} key={cellIndex} mobile={this.state.mobile} cell={cell} onClick={() => this.selectCell(cell, cellIndex)} />
          ))}
        </div>
      </div>
    );
  }
}
export default Grid;


