import React, { Component } from "react";

import styles from './Arrow.css';

export default class ArrowRight extends Component {
  render() {
    return (
      <svg className={styles.ArrowSvg} viewBox="0 0 70.81 109.66"><rect x="40.79" y="147.88" width="77.65" height="22.46" transform="translate(204.22 130.48) rotate(135)"/><rect x="40.76" y="109" width="77.65" height="22.46" transform="translate(6.65 176.68) rotate(-135)"/></svg>
    );
  }
}


