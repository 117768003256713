import React, { Component } from "react";

import Home from '../presentation/Home.jsx';

class HomeContainer extends Component {
  constructor() {
    super();
  }
  render() {
    return (
      <Home />
    );
  }
}
export default HomeContainer;