import React, { Component } from "react";
import { withRouter } from "react-router";

import ImageContainer from '../container/ImageContainer';
import Close from './Close';

import styles from './Team.css';
import mainStyles from './Main.css';

class Team extends Component {

  gridWheel(e) {
    this.refs.container.scrollLeft += (e.deltaY);
  }

  render() {
    const members = this.props.member.slice();
    members.sort((a,b) => a.sort - b.sort)

    return (
      <div className={[mainStyles.Component, mainStyles.Border, styles.Component].join(" ")}>
        <Close onClick={() => this.props.history.push('/agentur')}/>
        <div ref="container" className={styles.Component} onWheel={(e) => this.gridWheel(e)}>
          <div ref="grid" className={styles.MemberGrid} >
          {members.map((member,i) => {
              return (
                  <div className={styles.Member} key={member.id} style={{alignSelf: i%2==0?"flex-start":"flex-end"}}>
                      <ImageContainer className={styles.MemberImage} url={member.image.data.full_url} />
                      <div className={styles.MemberInfo} >
                          <p className={styles.MemberTitle}>
                              <strong>{member.name}</strong><br/>
                              <span>{member.position}</span>
                          </p>
                          <div dangerouslySetInnerHTML={{__html: member.text}} />
                      </div>
                  </div>
              )
          })}
        </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Team);


