import React, { Component } from "react";

import cms from '../cms.jsx';

class ImageContainer extends Component {
  render() {
    return (
      <img className={this.props.className} src={this.props.url} />
    );
  }
}
export default ImageContainer;


