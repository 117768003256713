import React, { Component } from "react";

import MediaContainer from '../container/MediaContainer';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import Close from './Close';
import styles from './Project.css';
import mainStyles from './Main.css';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {hideText: false};
  }

  toggleText() {
    this.setState((state) => {
      return {hideText: !state.hideText}
    });
  }

  gridWheel(e) {
    if (window.innerWidth >= 1024) {
      e.currentTarget.scrollLeft += (e.deltaY);
    }
  }

  render() {
    return (
      <div className={[styles.Component, mainStyles.Border].join(" ")}>
        <Close onClick={this.props.onCloseClick} />
        <div className={[styles.Content, styles.ContentElement].join(" ")}>
          <div className={this.state.hideText?styles.ContentContainerTextHidden:styles.ContentContainer}>
            <div className={this.state.hideText?styles.ContentTextHidden:styles.ContentText}>
              <div className={styles.ContentTextContainer} >
                <p>
                  <strong>{this.props.project.title}</strong><br />
                  <strong>{this.props.project.subtitle}</strong>
                </p>
                <div dangerouslySetInnerHTML={{__html: this.props.project.text}} />
              </div>
            </div>
            <button className={this.state.hideText?styles.ScrollButtonTextHidden:styles.ScrollButton} onClick={() => this.toggleText()} type="button">
              <svg  xmlns="http://www.w3.org/2000/svg" width="31" height="9"><defs><clipPath id="a" transform="translate(0 0)"><rect width="30.02" height="9" fill="none"/></clipPath></defs><title>pfeil</title><g clipPath="url(#a)"><line x1="0.95" y1="4.5" x2="30.02" y2="4.5" fill="none" stroke="#1d1d1b" strokeWidth="1.3"/><polyline points="5.28 8.52 0.95 4.5 5.28 0.48" fill="none" stroke="#1d1d1b" strokeWidth="1.3"/></g></svg>
              </button>
            <div ref="container" className={styles.ContentMediaContainer} onWheel={(e) => this.gridWheel(e)}>
            {this.props.project.media.map((m, i) => (
            <MediaContainer key={`media-${i}`} className={styles.ContentMedia} media={m.directus_files_id} />
            ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Project;


