exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports
var urlEscape = require("../../../node_modules/css-loader/dist/runtime/url-escape.js");
var ___CSS_LOADER_URL___0___ = urlEscape(require("../../assets/under-construction.svg"));
var ___CSS_LOADER_URL___1___ = urlEscape(require("../../assets/under-construction-ho.svg"));

// Module
exports.push([module.id, ".Empty_Component_ECNqZ {\n    display: -ms-grid;\n    display: grid;\n    box-sizing: border-box;\n    height: 100%;\n    padding-top: 50px;\n}\n\n.Empty_Background_2K2gT {\n    background: url(" + ___CSS_LOADER_URL___0___ + ");\n    background-position: bottom right;\n    background-size: contain;\n    background-repeat: no-repeat;\n    display: block;\n    box-sizing: border-box;\n    -ms-grid-row-align: stretch;\n        align-self: stretch;\n    -ms-grid-column-align: stretch;\n        justify-self: stretch;\n}\n\n.Empty_Close_1FJ2X {\n    top: 0px;\n    right: 0px;\n}\n\n.Empty_Component_ECNqZ h1 {\n    font-family: 'Trade Gothic LT Condensed';\n    line-height: 0.8;\n}\n\n@media (min-width: 1024px) {\n    .Empty_Background_2K2gT {\n        background: url(" + ___CSS_LOADER_URL___1___ + ");\n        background-position: bottom right;\n        background-size: contain;\n        background-repeat: no-repeat;\n        display: block;\n        box-sizing: border-box;\n        -ms-grid-row-align: stretch;\n            align-self: stretch;\n        -ms-grid-column-align: stretch;\n            justify-self: stretch;\n    }\n}", ""]);

// Exports
exports.locals = {
	"Component": "Empty_Component_ECNqZ",
	"Background": "Empty_Background_2K2gT",
	"Close": "Empty_Close_1FJ2X"
};