import React, { Component } from "react";
import { withRouter } from "react-router";

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';


import Grid from '../presentation/Grid.jsx';
import styles from '../presentation/Grid.css';
import ArrowLeft from '../presentation/ArrowLeft.jsx';
import ArrowRight from '../presentation/ArrowRight.jsx';
import ProjectCellContent from '../presentation/ProjectCellContent.jsx';
import Project from '../presentation/Project.jsx';
import Loading, {LoadingContext} from '../presentation/Loading'

import Close from '../presentation/Close.jsx';
import cms from '../cms.jsx';

import arrowLeftImage from '../../assets/arrow-left.svg';
import arrowRightImage from '../../assets/arrow-right.svg';
import oldImage from '../../assets/old.jpg';

import mainStyles from '../presentation/Main.css';

const grids = [
  [
    {fill: true, selectable: true, edges: {t: 0, l:0, b:65, r:55}},
    {fill: true, selectable: true, edges: {t: 0, l:45, b:35, r:0}},
    {fill: true, selectable: true, edges: {t: 35, l:0, b:0, r:70}},
    {fill: true, selectable: true, edges: {t: 65, l:30, b:0, r:42}},
    {edges: {t: 65, l:58, b:0, r:17}},
    {edges: {t: 35, l:30, b:35, r:55}},
    {content: (<div className={styles.Arrow}><ArrowRight /></div>), edges: {t: 65, l:83, b:0, r:0}, to: "/arbeit/2", selectable: true, bg: '#ffffff'}
  ],
  [
    {fill: true, selectable: true, edges: {t: 0, l:0, b:50, r:70}},
    {selectable: true, edges: {t: 0, l:30, b:50, r:60}},
    {fill: true, selectable: true, edges: {t: 0, l:40, b:50, r:35}},
    {fill: true, selectable: true, edges: {t: 0, l:65, b:50, r:0}},
    {content: (<div className={styles.Arrow}><ArrowLeft /></div>), edges: {t: 50, l:0, b:0, r:85}, to: "/arbeit", selectable: true, bg: '#ffffff'},
    {edges: {t: 50, l:15, b:0, r:77}},
    {fill: true, selectable: true, edges: {t: 50, l:23, b:0, r:50}},
    {edges: {t: 50, l:50, b:0, r:43}},
    {content: (<div className={styles.FullsizeStretch}><img src={oldImage} /></div>), edges: {t: 50, l:57, b:0, r:0}, to: "/arbeit/old", selectable: true, bg: '#ffffff'}
  ],
  [
    {fill: true, selectable: true, edges: {t: 0, l:0, b:50, r:65}},
    {edges: {t: 0, l:35, b:50, r:55}},
    {edges: {t: 0, l:80, b:40, r:0}},
    {fill: true, selectable: true, edges: {t: 0, l:45, b:40, r:20}},
    {content: (<div className={styles.Arrow}><ArrowLeft /></div>), edges: {t: 50, l:0, b:0, r:90}, to: "/arbeit/2", selectable: true, bg: '#ffffff'},
    {fill: true, selectable: true, edges: {t: 50, l:10, b:0, r:55}},
    {fill: true, selectable: true, edges: {t: 60, l:45, b:0, r:20}},
    {fill: true, selectable: true, edges: {t: 60, l:80, b:0, r:0}},
  ]
]

function getGrid(projects, index) {
  if(window.innerWidth > 1000) {
    return grids[index];
  }

  return projects.map(() => {
    return {fill: true, selectable: true, edges: {t: 0, l:0, b:0, r:0}}
  })
}

class ArbeitContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {loaded: 0}

  }



  componentDidMount() {
    cms.getItems("project", {fields: '*.*.*'})
      .then(res => this.setState({projects: res.data}))
      .catch(error => console.error(error));

    window.addEventListener("resize", this.forceUpdate)
  }

  componentnWillUnmount() {
    window.removeEventListener("resize", this.forceUpdate)
  }

  render() {
    const page = this.props.page
    const index = page == "old" ? 2 : page;

    var projectCount = 0;
    const cells = getGrid(this.state.projects || [], index);
    if (this.state.projects) {
      let projects = [...this.state.projects];

      projects.sort((a,b) => b.sort - a.sort);

      if (window.innerWidth >= 1024) {
        projects = projects.filter(p => {
          if (page == 0 && p.page == 'page1') return true;
          if (page == 1 && p.page == 'page2') return true;
          if (page == "old" && p.page == 'old') return true;
          return false;
        })
      }

      for (var i = 0; i < cells.length; i++) {
        const cell = cells[i];
        if (!cell.fill) {
          continue;
        }
        const project = projects.pop();
        if (project && project.cover && project.cover.data) {
          cell.project = project;
          cell.content = (
            <ProjectCellContent project={project} page={page} />
          )
          projectCount++;
        }
      }
    }

    console.log(this.state.loaded, projectCount)

    return (
      <LoadingContext.Provider value={{
          loading: !projectCount || projectCount > this.state.loaded,
          incrementLoaded: () => this.setState((state) => {
            return {...state, loaded: state.loaded + 1}
          }),
        }}>
        <TransitionGroup className={styles.GridTransition}>
          <Close className={styles.OuterClose} onClick={() => this.props.history.push("/")} />
          {(window.innerWidth >= 1024 || !this.state.selectedProject) && (
          <CSSTransition key={`grid-${this.props.page}`} timeout={500}>

            <Grid cells={cells} onCellSelected={(cell, index) => {
              if (cell.project) {
                this.setState({selectedProject: cell.project});
              }
              if (!cell.to) {
                return
              }
              this.props.history.push(cells[index].to);
            }} />
          </CSSTransition>
          )}
          {this.state.selectedProject && (
          <CSSTransition timeout={500}>
            <Project project={this.state.selectedProject} onCloseClick={() => this.setState({selectedProject: null})} />
          </CSSTransition>
          )}
          <Loading className={mainStyles.Border} />
        </TransitionGroup>
      </LoadingContext.Provider>
    );
  }
}

export default withRouter(ArbeitContainer);