import React from 'react';
import ReactDOM from "react-dom";
import cms from './cms';

import '../assets/fonts.css';

import SiteContainer from './container/SiteContainer.jsx';

const root = document.getElementById("root");

ReactDOM.render(<SiteContainer />, root);