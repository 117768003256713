import React, { Component } from "react";

import styles from './Media.css';
import mainStyles from './Main.css';

import cms from '../cms'
import Close from './Close'
import {MediaContext} from './Site'
import Video from './Video'

class Media extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderMedia(media) {
      if (media.type.substring(0,5) == 'video') {
      return <Video className={styles.ComponentVideo} isFullscreen={true} media={this.props.media} />

      }

      return <img className={styles.MediaContent} src={cms.url + "/thumbnail/_/1200/1200/contain/better/" + this.props.media.filename} />
    }

  render() {
    return (
      <MediaContext.Consumer>
        {(setMedia) => (
          <div className={[mainStyles.Component, mainStyles.Border, styles.Component].join(" ")}>
            <Close onClick={() => setMedia(null)} />
            {this.renderMedia(this.props.media)}
          </div>
        )}
      </MediaContext.Consumer>
    );
  }
}
export default Media;


