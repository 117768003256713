import React, { Component } from "react";

import Loading, {LoadingContext} from '../presentation/Loading'

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import cms from '../cms';

import styles from './ProjectCellContent.css';

class ProjectCellContent extends Component {
  constructor(props) {
    super(props);
  }

  gridWheel(e) {
    this.refs.container.scrollLeft += (e.deltaY);
  }

  showGif(e) {
    if (window.innerWidth < 1024) return;
    const imageUrl = `${cms.url}/thumbnail/_/1200/1200/contain/good/${this.props.project.cover.filename}`;
    const hoverUrl = `${this.props.project.cover_hover.data.full_url}`;
    e.target.src = hoverUrl || imageUrl
  }

  hideGif(e) {
    const imageUrl = `${cms.url}/thumbnail/_/1200/1200/contain/good/${this.props.project.cover.filename}`;
    const hoverUrl = `${this.props.project.cover_hover.data.full_url}`;
    e.target.src = imageUrl
  }

  render() {
    const project = this.props.project;

    const imageUrl = `${cms.url}/thumbnail/_/1200/1200/contain/good/${project.cover.filename}`;
    const hoverUrl = `${project.cover_hover.data.full_url}`;

    return (
        <div className={styles.Component}>
          <LoadingContext.Consumer>
            {(value) => (
            <img
              onLoad={() => value.incrementLoaded()}
              onError={() => value.incrementLoaded()}
              className={styles.Image} src={imageUrl} alt={project.title}
              onMouseOver={(e) => this.showGif(e)}
              onMouseOut={(e) => this.hideGif(e)}
              />
            )}
          </LoadingContext.Consumer>
        </div>
    );
  }
}
export default ProjectCellContent;


