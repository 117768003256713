import React, { Component } from "react";

import Team from '../presentation/Team.jsx';

import cms from '../cms.jsx';

class TeamContainer extends Component {
  constructor() {
    super();
    this.state = {
        member: []
    }
  }

  componentDidMount() {
    cms.getItems("team", {
        fields: '*.*'
    })
      .then(teamData => {
        const member = teamData.data;
        this.setState({member: member})
      })
      .catch(error => console.error(error));
  }


  render() {
    return (
      <Team member={this.state.member} />
    );
  }
}
export default TeamContainer;