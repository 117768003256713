exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Blog_PostDate_QSene {\n    color: #F28C00;\n    display: block;\n    font-size: 3rem;\n    font-weight: bold;\n    line-height: 1;\n    margin-bottom: 1rem;\n}\n\n.Blog_PostMedia_3petb {\n    height: 37.8vw;\n}\n\n.Blog_Post_1GUzj {\n    margin-bottom: 2rem;\n    padding: 0rem;\n}\n\n.Blog_Container_1IRE8 {\n    padding: 50px 1rem;\n}\n\n.Blog_PostTitle_2QoPC {\n    font-weight: bold;\n    font-size: 1rem;\n    margin-bottom: 2rem;\n}\n\n.Blog_PostContent_1d2Vg {\n    margin-bottom: 2rem;\n}\n\n@media (min-width: 1024px) {\n    .Blog_PostDate_QSene {\n        font-size: 4rem;\n        margin-bottom: 1.75rem;\n    }\n\n    .Blog_Container_1IRE8 {\n        position: absolute;\n        top: 0;\n        left: 0;\n        height: 100%;\n        width: 100%;\n        display: flex;\n        padding: 2rem;\n        overflow-x: scroll;\n    }\n\n    .Blog_Post_1GUzj {\n        padding: 0;\n        clear: both;\n        display: block;\n        margin-right: 4rem;\n        margin-bottom: 0;\n        display: flex;\n    }\n\n    .Blog_PostContent_1d2Vg {\n        min-width: 23rem;\n        padding-right: 3rem;\n        font-weight: bold;\n    }\n\n    .Blog_PostMedia_3petb > img,\n    .Blog_PostMedia_3petb > video {\n        max-width: none;\n        max-height: 100%;\n        width: auto;\n        margin: 0;\n    }\n}", ""]);

// Exports
exports.locals = {
	"PostDate": "Blog_PostDate_QSene",
	"PostMedia": "Blog_PostMedia_3petb",
	"Post": "Blog_Post_1GUzj",
	"Container": "Blog_Container_1IRE8",
	"PostTitle": "Blog_PostTitle_2QoPC",
	"PostContent": "Blog_PostContent_1d2Vg"
};