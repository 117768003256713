import React, { Component } from "react";

import { Link } from 'react-router-dom'

import styles from './Home.css';
import mainStyles from './Main.css';

import logoImage from '../../assets/logo.svg';
import closeImage from '../../assets/close.svg';

import Close from './Close';

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggle() {
    this.setState({
      closed: !this.state.closed
    })
  }

  render() {

    const homeClassNames = [styles.Component, mainStyles.Border];

    if (this.state.closed) {
      homeClassNames.push(styles.ClosedComponent);
    }

    return (
      <div className={homeClassNames.join(" ")}>
        <Close onClick={() => this.toggle()} className={styles.Close} closed={this.state.closed} />
        <div className={styles.Container}>
          <div className={mainStyles.HalfBorder}>
            <Link className={[styles.Link, styles.LinkAgentur].join(" ")} to="/agentur">Agentur</Link>
          </div>
          <div className={mainStyles.HalfBorder}>
            <Link className={[styles.Link, styles.LinkArbeit].join(" ")} to="/arbeit">Arbeit</Link>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;


