import React, { Component } from "react";

import About from '../presentation/About.jsx';
import cms from '../cms.jsx';

class AboutContainer extends Component {
  constructor() {
    super();
    this.state = {}
  }

  componentDidMount() {
    cms.getItem("about", 1, {fields: '*.*'})
      .then(res => this.setState({...res.data}))
      .catch(error => console.error(error));
  }

  render() {
    return (
      <About {...this.state} />
    );
  }
}
export default AboutContainer;