  import React, { Component } from "react";
import { HashRouter, Route, Switch, Link } from 'react-router-dom'
import Cookies from 'js-cookie';


import Empty from './Empty.jsx';
import ArbeitContainer from '../container/ArbeitContainer.jsx';
import AboutContainer from '../container/AboutContainer.jsx';
import AgenturContainer from '../container/AgenturContainer.jsx';
import HomeContainer from '../container/HomeContainer.jsx';
import KontaktContainer from '../container/KontaktContainer.jsx';
import TeamContainer from '../container/TeamContainer.jsx';
import BlogContainer from '../container/BlogContainer.jsx';
import ImpressumContainer from '../container/ImpressumContainer.jsx';

import Media from '../presentation/Media.jsx';

import logoAnimation from '../../assets/logo.json';
import herzImage from '../../assets/herz.svg';

import Lottie from 'react-lottie';

import styles from './Site.css';
import mainStyles from './Main.css';

import Loading, {LoadingContext} from  './Loading';

export const MediaContext = React.createContext(() => {});

import image1 from '../../assets/frontpage/1.jpg';
import image2 from '../../assets/frontpage/2.jpg';
import image3 from '../../assets/frontpage/3.jpg';
import image4 from '../../assets/frontpage/4.jpg';
import image5 from '../../assets/frontpage/5.jpg';
import image6 from '../../assets/frontpage/6.jpg';

const frontpageImages = [image1, image2, image3, image4, image5, image6];


class Site extends Component {
  constructor() {
    super();

    this.state = {
      loading: false
    };
  }

  setMedia(media) {
    this.setState({media: media})
    const video = this.refs.video;
    if (video) {
      if (media) {
        video.pause();
      } else {
        video.play();
      }
    }
  }

  componentDidMount() {
    this.setState({
      cookieConsent: Cookies.get('cookie-consent') == "i accept",
      mobileImage: frontpageImages[Math.floor(Math.random()*frontpageImages.length)]
    });
  }

  acceptCookieConset() {
    Cookies.set('cookie-consent', 'i accept')
    this.setState({cookieConsent: true});
  }

  showLogo() {
    this.setState({logoOut: window.innerWidth > 1024})
  }

  hideLogo() {
    this.setState({logoOut: false})
  }

  render() {
    const videoClassNames = [styles.Video];
    if (this.state.media) {
      videoClassNames.push(styles.VideoDim);
    }

    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: logoAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return (
      <LoadingContext.Provider value={{
        loading: this.state.loading || this.props.loading,
        setLoading: (loading) => this.setState({loading: loading})
      }}>
        <div>
          <MediaContext.Provider value={(media) => this.setMedia(media)}>
            {this.props.frontpage && window.innerWidth >= 1024 && (
            <div className={videoClassNames.join(" ")}>
               <iframe src="//player.vimeo.com/video/337744552?background=1" width="960" height="540" frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
            </div>
            )}

            {this.props.frontpage && window.innerWidth < 1024 && (
            <div className={videoClassNames.join(" ")}>
               <img src={this.state.mobileImage} alt="Background-Image" />
            </div>
            )}



            <HashRouter>
              <div className={styles.Container}>
                <header className={styles.Header}>
                  <Link to="/"
                    onMouseOver={() => this.showLogo()}
                    onMouseOut={() => this.hideLogo()}
                    >
                    <Lottie direction={this.state.logoOut?1:-1} className={styles.Logo} options={defaultOptions}
                      height={42}
                      width={390}
                    />
                  </Link>
                  <nav>
                    <Link to="/blog">
                      <svg  className={styles.Herz} viewBox="0 0 90.6 76.9"><defs><clipPath id="a" transform="translate(-381 -104.56)"><rect x="381" y="104.56" width="90.6" height="76.91" fill="none"/></clipPath></defs><g clipPath="url(#a)"><path d="M405.32,104.56c8.08.42,14.95,3.2,20,9.67.91,1.16,1,1.16,1.88,0a24.81,24.81,0,0,1,14-8.9c10.28-2.33,21.34,1.48,26.93,10.26a20.62,20.62,0,0,1,3.16,14.47A30.56,30.56,0,0,1,463,147a132.92,132.92,0,0,1-13.74,12.23c-4.24,3.44-8.53,6.86-12.44,10.66-3.43,3.32-6.38,7.13-9.51,10.75-.94,1.09-.86,1.1-1.8.07-3.18-3.46-6.19-7.1-9.61-10.3-4.27-4-8.86-7.62-13.37-11.34s-9.12-7.33-13.07-11.68a32.07,32.07,0,0,1-7.6-13.62c-3.5-13.33,4.13-24.16,15.11-27.64a24.16,24.16,0,0,1,3.28-1c1.69-.29,3.42-.4,5.07-.58" transform="translate(-381 -104.56)" fill="#000000"/></g></svg>
                    </Link>
                    <Link to="/kontakt">
                      Kontakt
                    </Link>
                  </nav>
                </header>
                <main className={[styles.Main, mainStyles.Playground].join(" ")}>
                  <Switch>
                    <Route path="/" exact component={HomeContainer} />
                    <Route path="/arbeit" exact render={() => <ArbeitContainer page={0} />} />
                    <Route path="/arbeit/2" exact render={() => <ArbeitContainer page={1} />} />
                    <Route path="/arbeit/3" exact render={() => <ArbeitContainer page={2} />} />
                    <Route path="/arbeit/old" exact render={() => <ArbeitContainer page={"old"} />} />
                    <Route path="/agentur/team" exact component={TeamContainer} />
                    <Route path="/blog" exact component={BlogContainer} />
                    <Route path="/agentur" exact component={AgenturContainer} />
                    <Route path="/kontakt" exact component={KontaktContainer} />
                    <Route path="/ueber-uns" exact component={AboutContainer} />
                    <Route path="/impressum+datenschutz" exact component={ImpressumContainer} />
                    <Route path="/" component={Empty} />
                  </Switch>
                  {this.state.media && (
                  <Media media={this.state.media} />
                  )}
                </main>
                <footer className={styles.Footer}>
                  <Link to="/impressum+datenschutz" className={styles.ImpressumLink}>
                    Impressum / Datenschutz
                  </Link>
                </footer>
              </div>
            </HashRouter>
          </MediaContext.Provider>
          <Loading fixed={true} />
          {!this.state.cookieConsent && (
          <div className={styles.CookieConsent}>
            Diese Webseite verwendet Cookies - nähere Informationen dazu finden Sie in unserer Datenschutzerklärung. Klick Sie auf „Wenn's sein muss“, um Cookies zu akzeptieren und unsere Webseite besuchen zu können. <button onClick={() => this.acceptCookieConset()} className={styles.CookieConsentButton}>Wenn's sein muss</button>
          </div>
          )}
        </div>
      </LoadingContext.Provider>
    );
  }
}
export default Site;


