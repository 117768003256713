import React, { Component } from "react";

import { withRouter } from "react-router";

import styles from './Kontakt.css';
import mainStyles from './Main.css';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

import fitty from 'fitty';

import Close from './Close.jsx';

class Kontakt extends Component {
    constructor(props) {
        super(props)
        this.state = {index: 0}
        this.interval = null;
        this.fitties = [];
        this.fittyOptions  = {
            maxSize: 1000,
            observeMutations: {
              subtree: false,
              childList: false,
              characterData: true
            }
          }

    }

  componentDidMount() {
    this.updateFitties();

    if (window.innerWidth < 1024) {
      let counter = 0;
      let interval = setInterval(() => {
        if (window.innerWidth >= 1024) {
          clearInterval(interval)
        }
        this.setState((s) => this.setState({index: (s.index+1) % this.props.contact.length}))
      }, 2000)
    }
  }

  componentDidUpdate() {
    this.updateFitties();
  }

  componentWillUnmount() {
    clearInterval(this.fittyInterval);
  }

  updateFitties() {
    this.fitties = fitty("." + styles.Text, this.fittyOptions);
  }

  resetContact(x, y) {
    this.setState({index: ((x - x % 10) + (y - y % 10)) % this.props.contact.length})
  }

  render() {
    const contact = this.props.contact || [];

    return (
      <div className={[mainStyles.Component, styles.Component, mainStyles.Border].join(' ')}
        onMouseMove={(e) => this.resetContact(e.clientX, e.clientY)}
        onTouchMove={(e) => this.resetContact(e.clientX, e.clientY)}
        >
        <Close onClick={() => this.props.history.push('/')} />
        <div className={styles.TextContainer}>
            {contact.map((c,i) => (
            <a key={'c' + i} className={styles.Text} style={{visibility: this.state.index == i ? 'visible': 'hidden'}} target="_blank" href={c.href}>{c.text}</a>
            ))}
        </div>
      </div>
    );
  }
}
export default withRouter(Kontakt);


