import React, { Component } from "react";
import { withRouter } from "react-router";

import Grid from '../presentation/Grid.jsx';
import styles from '../presentation/Grid.css';

import Close from '../presentation/Close';

class AgenturContainer extends Component {
  constructor() {
    super();
  }
  render() {
    const cells = [
        {content: (<div className={styles.Fullsize}><h1 className={styles.CellTitle}>Team</h1></div>), bg: '#F18B24', selectable: true, edges: {t: 0, l:0, b:0, r:60}, to: "/agentur/team"},
        {bg: undefined, edges: {t: 0, l:40, b:30, r:40}},
        {content: (<div className={styles.Fullsize}><h1 className={styles.CellTitle}>Über uns</h1></div>), bg: '#F18B24', selectable: true, edges: {t: 0, l:60, b:30, r:0}, to: "/ueber-uns"},
        {content: (<div className={styles.Fullsize}><h1 className={styles.CellTitle}>Rundflug</h1></div>), bg: '#FFFFFF', selectable: true, edges: {t: 70, l:40, b:0, r:15}, to: "/rundflug"},
        {bg: undefined, edges: {t: 70, l:85, b:0, r:0}}
    ]

    return (
      <div>
        <Close className={styles.OuterClose} onClick={() => this.props.history.push('/')} />
        <Grid cells={cells} onCellSelected={(cell, index) => {
          if (!cell.to) return;
          this.props.history.push(cell.to);
        }} />
      </div>
    );
  }
}
export default withRouter(AgenturContainer);