import React, { Component } from "react";

import styles from './Video.css';

class Video extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  play() {
    var elements  = document.getElementsByTagName("video");
    for (var i = elements.length - 1; i >= 0; i--) {
      elements[i].pause()
    }

    this.refs.video.play();
  }

  pause() {
    this.refs.video.pause();
  }

  render() {
    return (
      <div className={[styles.Container, this.props.className].join(" ")}>
        <video ref="video" onCanPlay={this.props.onCanPlay}
          onPlay={() => this.setState({play: true})}
          onPause={() => this.setState({play: false})}
          >
            <source src={this.props.media.data.full_url} />
        </video>
        <div className={styles.Controls}>
          {!this.state.play && (
          <button className={styles.PlayButton} onClick={() => this.play()}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 853 853"><title>Play_Button</title><path d="M853,410v33c-.48,1.06-1.36,2.12-1.37,3.19-.15,20.72-3.26,41.1-7,61.41a409.53,409.53,0,0,1-30.36,95.51,418.82,418.82,0,0,1-37.15,65.48,454.68,454.68,0,0,1-48.06,58,268.57,268.57,0,0,1-23.12,21.11c-13.83,11.23-27.67,22.54-42.27,32.7a338.09,338.09,0,0,1-67,36.44,610.89,610.89,0,0,1-58.31,20.54,352.5,352.5,0,0,1-92.68,14.28c-.9,0-1.79.89-2.69,1.36H410c-1.06-.48-2.12-1.36-3.19-1.37-20.72-.15-41.1-3.26-61.41-7a409.53,409.53,0,0,1-95.51-30.36,417.57,417.57,0,0,1-65.48-37.15,447,447,0,0,1-58-48.06,291.5,291.5,0,0,1-22.16-24.19c-10.92-13.44-21.81-27-31.66-41.2a341.2,341.2,0,0,1-36.43-67,610.89,610.89,0,0,1-20.54-58.31A352.5,352.5,0,0,1,1.36,445.69c0-.9-.89-1.79-1.36-2.69V410c.48-1.06,1.36-2.12,1.37-3.19.15-20.72,3.26-41.1,7-61.41a409.53,409.53,0,0,1,30.36-95.51A417.57,417.57,0,0,1,75.9,184.41a447,447,0,0,1,48.06-58,291.5,291.5,0,0,1,24.19-22.16c13.44-10.92,27-21.81,41.2-31.66a341.2,341.2,0,0,1,67-36.43,610.89,610.89,0,0,1,58.31-20.54A352.5,352.5,0,0,1,407.31,1.36c.9,0,1.79-.89,2.69-1.36h33c1.06.48,2.12,1.36,3.19,1.37,20.72.15,41.1,3.26,61.41,7a409.53,409.53,0,0,1,95.51,30.36A418.82,418.82,0,0,1,668.59,75.9a454.68,454.68,0,0,1,58,48.06,268.57,268.57,0,0,1,21.11,23.12c11.23,13.83,22.54,27.67,32.7,42.27a338.09,338.09,0,0,1,36.44,67,610.89,610.89,0,0,1,20.54,58.31,352.5,352.5,0,0,1,14.28,92.68C851.65,408.21,852.53,409.1,853,410ZM284.6,426.5c0,59.83-.11,119.67.2,179.5a34.55,34.55,0,0,0,4.5,16c6.79,12.1,25.53,20,44.15,8.14q139-88.65,278.38-176.7c21.79-13.84,21.79-40,0-53.81q-81.78-52-163.69-103.83Q389.91,258.86,331.67,222c-10.33-6.53-21.05-7.09-31.69-1.11s-15.47,15.33-15.44,27.66C284.68,307.84,284.6,367.17,284.6,426.5Z"/></svg>
          </button>
          )}
          {this.state.play && (
          <button className={styles.PauseButton} onClick={() => this.pause()}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 853 853"><title>Pause_Button</title><path d="M410,0h33c1.06.48,2.12,1.36,3.19,1.37,20.72.15,41.1,3.26,61.41,7a409.53,409.53,0,0,1,95.51,30.36A418.82,418.82,0,0,1,668.59,75.9a454.68,454.68,0,0,1,58,48.06,268.57,268.57,0,0,1,21.11,23.12c11.23,13.83,22.54,27.67,32.7,42.27a338.09,338.09,0,0,1,36.44,67,610.89,610.89,0,0,1,20.54,58.31,352.5,352.5,0,0,1,14.28,92.68c0,.9.89,1.79,1.36,2.69v33c-.48,1.06-1.36,2.12-1.37,3.19-.15,20.72-3.26,41.1-7,61.41a409.53,409.53,0,0,1-30.36,95.51,418.82,418.82,0,0,1-37.15,65.48,454.68,454.68,0,0,1-48.06,58,268.57,268.57,0,0,1-23.12,21.11c-13.83,11.23-27.67,22.54-42.27,32.7a338.09,338.09,0,0,1-67,36.44,610.89,610.89,0,0,1-58.31,20.54,352.5,352.5,0,0,1-92.68,14.28c-.9,0-1.79.89-2.69,1.36H410c-1.06-.48-2.12-1.36-3.19-1.37-20.72-.15-41.1-3.26-61.41-7a409.53,409.53,0,0,1-95.51-30.36,417.57,417.57,0,0,1-65.48-37.15,447,447,0,0,1-58-48.06,291.5,291.5,0,0,1-22.16-24.19c-10.92-13.44-21.81-27-31.66-41.2a341.2,341.2,0,0,1-36.43-67,610.89,610.89,0,0,1-20.54-58.31A352.5,352.5,0,0,1,1.36,445.69c0-.9-.89-1.79-1.36-2.69V410c.48-1.06,1.36-2.12,1.37-3.19.15-20.72,3.26-41.1,7-61.41a409.53,409.53,0,0,1,30.36-95.51A417.57,417.57,0,0,1,75.9,184.41a447,447,0,0,1,48.06-58,291.5,291.5,0,0,1,24.19-22.16c13.44-10.92,27-21.81,41.2-31.66a341.2,341.2,0,0,1,67-36.43,610.89,610.89,0,0,1,58.31-20.54A352.5,352.5,0,0,1,407.31,1.36C408.21,1.35,409.1.47,410,0ZM298.56,426.39v180.9c0,7.32,1.76,9.14,9,9.14q38,0,76,0c7.2,0,8.95-1.83,8.95-9.16V246c0-7.7-1.64-9.38-9.22-9.39q-37.72,0-75.46,0c-7.56,0-9.19,1.69-9.19,9.42Zm255.87.61V246c0-7.74-1.62-9.42-9.18-9.43h-75.5c-7.4,0-9.18,1.75-9.18,8.93v362c0,7.18,1.78,8.92,9.18,8.93q37.75,0,75.5,0c7.56,0,9.18-1.69,9.18-9.43Z"/></svg>
          </button>
          )}
          {!this.props.isFullscreen && (
          <button onClick={() => {
            this.pause();
            this.props.onEnterFullscreen();
          }}>
            <svg className={styles.FullscreenButton} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 436 436"><title>Vollbild</title><path d="M0,315H29.91v90.88H120V436H0Z"/><path d="M316,436V406.06h89.9V315H436V436Z"/><path d="M120,0V29.94H30.1V120H0V0Z"/><path d="M436,120H406.09V30.12H316V0H436Z"/></svg>
          </button>
          )}
        </div>
      </div>
    );
  }
}
export default Video;
