import React, { Component } from "react";

import cms from '../cms'

import {MediaContext} from '../presentation/Site'
import styles from '../presentation/Media.css'
import Video from '../presentation/Video'
import Loading, {LoadingContext} from '../presentation/Loading'



class MediaContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {loading: true}
    this.fullscreenChangeHandler = (e) => this.fullscreenChange(e)
    this.videoRef = React.createRef();
  }

  fullScreen(media, setMedia) {
    if (window.innerWidth < 1024) return;
    setMedia(media);
  }

  exitFullScreen(setMedia) {
    setMedia(null);
  }

  render() {
    if (this.props.media.type == 'video/mp4') {
        return (
        <LoadingContext.Provider value={{
          loading: this.state.loading
        }}>
          <MediaContext.Consumer>
            {(setMedia) => (
              <Video className={[styles.MediaContainer, this.props.className].join(" ")} media={this.props.media}onCanPlay={() => this.setState({loading: false})} onEnterFullscreen={() => this.fullScreen(this.props.media, setMedia)} onExitFullscreen={() => this.exitFullScreen(setMedia)} />
            )}
          </MediaContext.Consumer>
          <Loading />
        </LoadingContext.Provider>
        )
    }

    return (
    <LoadingContext.Provider value={{
          loading: this.state.loading
        }}>
      <MediaContext.Consumer>
      {(setMedia) => (
      <div className={[styles.MediaContainer, this.props.className].join(" ")}>
        <img
          onError={() => this.setState({loading: false})}
          onLoad={() => this.setState({loading: false})}
          style={{cursor: "pointer"}} src={cms.url + "/thumbnail/_/1200/1200/contain/better/" + this.props.media.filename}
          onClick={() => this.fullScreen(this.props.media, setMedia)} />
      </div>
      )}
      </MediaContext.Consumer>
      </LoadingContext.Provider>
    );
  }
}
export default MediaContainer;


