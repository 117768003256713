exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Team_Component_2l8IB {\n    overflow-x: scroll;\n}\n\n.Team_MemberImage_3X0iQ {\n    max-width: 100%;\n    margin-bottom: 1rem;\n}\n\n.Team_Member_3wTxY {\n    margin-bottom: 2rem;\n}\n\n.Team_MemberTitle_26cav {\n    font-weight: bold;\n}\n\n.Team_MemberInfo_YaHid {\n    padding: 0 5px;\n}\n\n@media (min-width: 1024px) {\n    .Team_MemberGrid_3DzuS {\n        padding: 1rem;\n        display: flex;\n        height: 100%;\n    }\n\n    .Team_MemberInfo_YaHid p {\n        word-break: break-word;\n    }\n\n    .Team_MemberTitle_26cav {\n        font-weight: inherit;\n    }\n\n    .Team_MemberImage_3X0iQ {\n        width: 100%;\n        margin: 0;\n        max-height: 33rem;\n        -o-object-fit: cover;\n           object-fit: cover;\n    }\n\n    .Team_Member_3wTxY {\n        margin: 0;\n        padding: 0;\n        display: -ms-grid;\n        display: grid;\n        -ms-grid-columns: 300px 15px 330px;\n        grid-template-columns: 300px 330px;\n        grid-gap: 15px;\n        padding-right: 8rem;\n        font-weight: bold;\n    }\n\n    .Team_Member_3wTxY:last-child {\n        padding-right: 10rem;\n    }\n}\n\n@media (min-width: 1440px) {\n    .Team_Member_3wTxY {\n        -ms-grid-columns: 400px 15px 430px;\n        grid-template-columns: 400px 430px;\n    }\n}\n\n@media (min-width: 1600px) {\n    .Team_Member_3wTxY {\n        -ms-grid-columns: 500px 15px 430px;\n        grid-template-columns: 500px 430px;\n    }\n}", ""]);

// Exports
exports.locals = {
	"Component": "Team_Component_2l8IB",
	"MemberImage": "Team_MemberImage_3X0iQ",
	"Member": "Team_Member_3wTxY",
	"MemberTitle": "Team_MemberTitle_26cav",
	"MemberInfo": "Team_MemberInfo_YaHid",
	"MemberGrid": "Team_MemberGrid_3DzuS"
};