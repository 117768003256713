import React, { Component } from "react";

import fitty from 'fitty';
import { withRouter } from "react-router";

import Close from './Close'

import styles from './Empty.css';
import mainStyles from './Main.css';

class Empty extends Component {
  render() {
    return (
      <div className={[mainStyles.Component, styles.Component, mainStyles.Border].join(" ")}>
        <Close onClick={() => this.props.history.push('/agentur')} />
        <span className={styles.Background} />
      </div>
    );
  }
}
export default withRouter(Empty);


