import React, { Component } from "react";

import { withRouter } from "react-router";

import styles from './About.css';
import mainStyles from './Main.css';

import Close from './Close.jsx';

class About extends Component {
    constructor(props) {
        super(props)
    }

    gridWheel(e) {
    this.refs.container.scrollLeft += (e.deltaY);
  }

  render() {
    const backgroundUrl = this.props.background_desktop ? (window.innerWidth >= 1024 ?
      this.props.background_desktop.data.full_url :
      this.props.background_mobile.data.full_url  ): null;

    return (
      <div className={[mainStyles.Component, mainStyles.Border, styles.Component].join(' ')}>
        <Close onClick={() => this.props.history.push('/agentur')} />
        {this.props.background_desktop && (
        <div className={styles.Container} ref="container" onWheel={(e) => this.gridWheel(e)}>
          <div className={styles.Background} style={{backgroundImage: "url(" + backgroundUrl + ")"}}>
            <div className={styles.BackgroundGrid}>
              <div className={styles.ContainerElement}><div dangerouslySetInnerHTML={{__html: this.props.text_1}}></div>
              </div>
              <div className={styles.ContainerElement} >
                  <div dangerouslySetInnerHTML={{__html: this.props.text_2}}></div>
              </div>
              <div className={styles.ContainerElement}>
                <div dangerouslySetInnerHTML={{__html: this.props.text_3}}></div>
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }
}
export default withRouter(About);


