import React, { Component } from "react";

import { Link } from 'react-router-dom'
import moment from 'moment'

import { withRouter } from "react-router";

import styles from './Blog.css';
import mainStyles from './Main.css';

import Close from './Close.jsx';

import MediaContainer from '../container/MediaContainer'

class Blog extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  toggle() {
    this.setState({
      closed: !this.state.closed
    })
  }

  gridWheel(e) {
    this.refs.container.scrollLeft += (e.deltaY);
  }

  render() {

    const classNames = [mainStyles.Component, mainStyles.Border];
    const posts = this.props.posts || [];


    return (
      <div className={classNames.join(" ")}>
        <Close onClick={() => this.props.history.push('/agentur')} />
        <div className={styles.Container} ref="container" onWheel={(e) => this.gridWheel(e)}>
          {posts.map(p => {
            return (<article key={p.id} className={styles.Post}>
              <div className={styles.PostContent}>
                <span className={styles.PostDate}>{moment(p.date, 'YYYY-MM-DD').format('MM.YY')}</span>
                <h1 className={styles.PostTitle}>{p.title}</h1>
                <div className={styles.PostText} dangerouslySetInnerHTML={{__html: p.text}} />
              </div>
              <MediaContainer media={p.image} className={styles.PostMedia} />
            </article>
            )
          })}
        </div>
      </div>
    );
  }
}
export default withRouter(Blog);


