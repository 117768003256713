import React, { Component } from "react";

import Blog from '../presentation/Blog.jsx';
import cms from '../cms.jsx';

class BlogContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount() {
    cms.getItems("posts", {fields: '*.*.*'})
      .then(res => this.setState({posts: res.data}))
      .catch(error => console.error(error));
  }

  render() {
    return (
      <Blog posts={this.state.posts} />
    );
  }
}
export default BlogContainer;