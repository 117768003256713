exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Video_Container_O8EDa {\n    position: relative;\n    width: auto;\n    width: -webkit-fit-content;\n    width: -moz-fit-content;\n    width: fit-content;\n}\n\n.Video_Controls_3V3Dj {\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    top: 0;\n    display: flex;\n    justify-content: flex-end;\n    align-items: flex-end;\n    box-sizing: border-box;\n    padding: 0.5rem;\n}\n\n.Video_Controls_3V3Dj button {\n    font-family: 'Trade Gothic LT Condensed';\n    background: none;\n    border: none;\n    padding: 0rem;\n    display: inline-block;\n    width: 80px;\n    height: 80px;\n    cursor: pointer;\n}\n\n.Video_Controls_3V3Dj button:hover path {\n    fill: #f28c00;\n}\n\n.Video_Controls_3V3Dj button:focus {\n    outline: none;\n}\n\n.Video_Controls_3V3Dj .Video_PauseButton_10ot1,\n.Video_Controls_3V3Dj .Video_FullscreenButton_3baxn {\n    opacity: 0;\n    transition: opacity 150ms linear;\n}\n\n.Video_Controls_3V3Dj .Video_FullscreenButton_3baxn {\n    display: none;\n}\n\n\n.Video_Controls_3V3Dj:hover .Video_PauseButton_10ot1,\n.Video_Controls_3V3Dj:hover .Video_FullscreenButton_3baxn {\n    opacity: 1;\n}\n\n.Video_Controls_3V3Dj .Video_PlayButton_3u6AD {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n.Video_Controls_3V3Dj .Video_PauseButton_10ot1 {\n    position: absolute;\n    bottom: 1.5rem;\n    left: 1.5rem;\n    width: 40px;\n    height: 40px;\n}\n\n.Video_Play_2sC9a {\n    position: absolute;\n    top: 0;\n    left: 0;\n    height: 100%;\n    width: 100%;\n}\n\n.Video_Play_2sC9a svg {\n\n}\n\n@media (min-width: 1024px) {\n    .Video_Controls_3V3Dj .Video_FullscreenButton_3baxn {\n        display: inline-block;\n    }\n\n    .Video_Container_O8EDa video {\n        height: 100%;\n    }\n}", ""]);

// Exports
exports.locals = {
	"Container": "Video_Container_O8EDa",
	"Controls": "Video_Controls_3V3Dj",
	"PauseButton": "Video_PauseButton_10ot1",
	"FullscreenButton": "Video_FullscreenButton_3baxn",
	"PlayButton": "Video_PlayButton_3u6AD",
	"Play": "Video_Play_2sC9a"
};