import React, { Component } from "react";

import styles from './Arrow.css';

export default class ArrowLeft extends Component {
  render() {
    return (
      <svg className={styles.ArrowSvg} viewBox="0 0 70.81 109.66"><rect x="40.76" y="109" width="77.65" height="22.46" transform="translate(-105.89 6.65) rotate(-45)"/><rect x="40.79" y="147.88" width="77.65" height="22.46" transform="translate(91.63 -94.53) rotate(45)"/></svg>
    );
  }
}


