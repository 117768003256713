import React, { Component } from "react";

import styles from './Close.css';
import mainStyles from './Main.css';

import closeImage from '../../assets/close.svg';

class Close extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const deg = this.props.closed ? 45 : 0;

    return (
      <button className={[styles.Close, mainStyles.Border, mainStyles.PullTopRight, this.props.className].join(" ")} onClick={() => this.props.onClick()}>
        <svg x="0px" y="0px" viewBox="10 10 135 135" style={{enableBackground: "new 0 0 155 155", transform: `rotateZ(${deg}deg)`, transition: "transform 250ms ease-in-out"}}>
        <line stroke="#000000" strokeWidth="14" strokeMiterlimit="10" x1="54" y1="50" x2="105" y2="101"/>
        <line stroke="#000000" strokeWidth="14" strokeMiterlimit="10" x1="105" y1="50" x2="54" y2="101"/>
        </svg>
      </button>
    );
  }
}
export default Close;


