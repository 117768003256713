exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Impressum_Component_1JXOD {\n    position: relative;\n    height: 100%;\n}\n\n.Impressum_Content_6OKuC {\n    padding: 60px 30px;\n}\n\n\n@media (min-width: 1024px) {\n    .Impressum_Component_1JXOD {\n        overflow: hidden;\n    }\n\n    .Impressum_Content_6OKuC {\n        display: -ms-grid;\n        display: grid;\n        font-size: 0.85rem;\n        align-items: center;\n        padding-right: 0rem;\n        -ms-grid-columns: 19rem 20rem 1fr;\n        grid-template-columns: 19rem 20rem 1fr;\n        padding: 0;\n    }\n\n    .Impressum_ContentElement_1qUfB {\n        padding: 0 3rem;\n    }\n\n    .Impressum_ContentElement_1qUfB:first-child {\n        min-width: 19rem;\n        padding-left: 6rem;\n    }\n\n    .Impressum_ContentElement_1qUfB:nth-child(2) {\n        min-width: 20rem;\n    }\n\n    .Impressum_ContentElement_1qUfB:nth-child(3) {\n        overflow-y: scroll;\n        padding: 60px 0;\n    }\n\n    .Impressum_ContentElementColumns_-JpMX {\n        columns: 24rem auto;\n        column-fill: balance;\n        height: 30vw;\n        column-gap: 2rem;\n    }\n\n    .Impressum_ContentElementColumns_-JpMX p {\n        padding-right: 7rem;\n    }\n\n    .Impressum_ContentElementColumns_-JpMX u,\n    .Impressum_ContentElementColumns_-JpMX b {\n        break-after: avoid;\n    }\n\n    .Impressum_ContentElementColumns_-JpMX p {\n        break-inside: avoid;\n    }\n}", ""]);

// Exports
exports.locals = {
	"Component": "Impressum_Component_1JXOD",
	"Content": "Impressum_Content_6OKuC",
	"ContentElement": "Impressum_ContentElement_1qUfB",
	"ContentElementColumns": "Impressum_ContentElementColumns_-JpMX"
};