exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Loading_Base_16CYU {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: white;\n    perspective: 250px;\n}\n\n.Loading_Base_16CYU.enter {\n  opacity: 0;\n  display: block;\n}\n\n.Loading_Base_16CYU.enter-active {\n  opacity: 1;\n  transition: opacity 750ms ease-in-out;\n}\n\n\n.Loading_Base_16CYU.exit {\n  opacity: 1;\n}\n.Loading_Base_16CYU.exit-active {\n  opacity: 0;\n  transition: opacity 750ms ease-in-out 1000ms;\n}\n\n.Loading_Fixed_2XSzb {\n    position: fixed;\n    z-index: 2500;\n}\n\n.Loading_Logo_3LqSB {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    height: 3rem;\n    width: 5.77rem;\n\n    animation-name: Loading_spin_6EGxQ;\n    animation-duration: 4000ms;\n    animation-iteration-count: infinite;\n    animation-timing-function: linear;\n}\n\n@keyframes Loading_spin_6EGxQ { \n    from { \n        transform: translate(-50%, -50%) rotateY(0deg); \n    } to { \n        transform: translate(-50%, -50%) rotateY(360deg); \n    }\n}", ""]);

// Exports
exports.locals = {
	"Base": "Loading_Base_16CYU",
	"Fixed": "Loading_Fixed_2XSzb",
	"Logo": "Loading_Logo_3LqSB",
	"spin": "Loading_spin_6EGxQ"
};