import React, { Component } from "react";

import styles from './Loading.css';
import logo from '../../assets/logo.svg';

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';

export const LoadingContext = React.createContext({
  loading: false,
  setLoading: () => {}
});

class Loading extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const classNames = [styles.Base, this.props.className];
    if (this.props.fixed) {
      classNames.push(styles.Fixed);
    }

    return (
      <TransitionGroup>
        <LoadingContext.Consumer>
          {({loading}) => (
          <CSSTransition in={loading} unmountOnExit timeout={2750}>
            <div className={[classNames.join(" ")]}>
              <img src={logo} className={styles.Logo} />
            </div>
          </CSSTransition>
          )}       
        </LoadingContext.Consumer>
      </TransitionGroup>
    );
  }
}
export default Loading;


